
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.admin-main {
  &__welcome {
    width: 100%;
    position: relative;
    background-color: $color-white;
    min-height: 168px;
    padding: 32px;
    border-radius: 16px;
    overflow: hidden;
    @include for-size(tablet-portrait-down) {
      width: 100%;
    }
    @include for-size(phone-portrait-down) {
      min-height: 98px;
    }
  }

  &__title {
    width: 460px;
    @include for-size(phone-portrait-down) {
      max-width: 180px;
    }

    p {
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__picture {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 168px;
    width: 251px;

    @include for-size(phone-portrait-down) {
      width: 120px;
      height: 98px;
      right: -10px;
    }
  }
}
